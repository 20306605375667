import settings from '@/settings';
import { baseRestService } from '@/services/_base/baseRestService'

class CorsiService extends baseRestService {

  constructor() {
    super();
    this.baseUrl = () => settings.baseUrl;
  }

  async Count(params?: corso.queryParams): Promise<number> {
    return await this.Get<number>('/api/corsi/count', params)
  }

  async List(params?: corso.queryParams): Promise<Array<corso.item>> {
    return await this.Get<Array<corso.item>>('/api/corsi/', params)
  }

  async Detail(id: string): Promise<corso.item> {
    return await this.Get<corso.item>(`/api/corsi/${id}`)
  }

  async Set(corso: corso.item): Promise<corso.item> {
    return await this.Post('/api/corsi', corso)
  }

  async ChangeStato(id: string, stato: string, motivazione: string = null): Promise<corso.item> {
    return await this.Put(`/api/corsi/change-stato/${id}`, { stato, motivazione })
  }

  async Remove(id: string) {
    return await this.Delete(`/api/corsi/${id}`)
  }

  async Duplicate(id: string): Promise<corso.item> {
    return await this.Get(`/api/corsi/${id}/duplicate`);
  }

  async CalcolaCreditiSuggeriti(id: string): Promise<number> {
    return await this.Get(`/api/corsi/${id}/crediti-suggeriti`);
  }

  async CheckUsedUri(uri: string): Promise<Array<corso.item>> {
    return await this.Get(`/api/corsi/luogo/check-used-uri?uri=${uri}`);
  }
}

export const corsiService = new CorsiService()
