import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";

class IscrittoService extends baseRestService {


    constructor() {
        super();
        this.baseUrl = () => settings.baseUrl;
    }

    async Count(corsoId: string, params?: iscritto.queryParams): Promise<number> {
        return await this.Get<number>(`/api/corsi/${corsoId}/iscritti/count`, params);
    }

    async List(corsoId: string, params?: iscritto.queryParams): Promise<iscritto.item[]> {
        return await this.Get<iscritto.item[]>(`/api/corsi/${corsoId}/iscritti`, params);
    }

    async Giornalista(anagraficaGiornalistaId: string, params?: iscritto.queryParams): Promise<iscritto.item[]> {
        return await this.Get<iscritto.item[]>(`/api/iscrizioni/${anagraficaGiornalistaId}/corsi`, params);
    }

    async Detail(corsoId: string, anagraficaGiornalistaId: string): Promise<iscritto.item> {
        return await this.Get<iscritto.item>(`/api/corsi/${corsoId}/iscritti/${anagraficaGiornalistaId}`);
    }

    async Iscriviti(corsoId: string, anagraficaGiornalistaId: string): Promise<iscritto.item> {
        return await this.Put<iscritto.item>(`/api/corsi/${corsoId}/iscritti/iscrizione/${anagraficaGiornalistaId}`, null);
    }

    async Ammetti(corsoId: string, anagraficaGiornalistaId: string): Promise<iscritto.item> {
        return await this.Put<iscritto.item>(`/api/corsi/${corsoId}/iscritti/ammissione/${anagraficaGiornalistaId}`, null);
    }

    async Partecipanti(corsoId: string, rows: iscritto.partecipante[]): Promise<iscritto.item> {
        return await this.Post<iscritto.item>(`/api/corsi/${corsoId}/iscritti/partecipanti`, rows, null);
    }

    async SetGiustificazione(corsoId: string, anagraficaGiornalistaId: string, giustificazione: boolean): Promise<void> {
        return await this.Post(`/api/corsi/${corsoId}/iscritti/giustifica/${anagraficaGiornalistaId}`, { giustificazione });
    }

    async Remove(corsoId: string, anagraficaGiornalistaId: string, motivo: string): Promise<void> {
        return await this.Delete(`/api/corsi/${corsoId}/iscritti/${anagraficaGiornalistaId}`, { motivo });
    }
}

export const iscrittoService = new IscrittoService();