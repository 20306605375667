import { Vue, Component } from 'vue-property-decorator';
import { cnogService } from '../service/cnogService';

@Component({
  name: 'ListaCnog'
})
export default class ListaCnog extends Vue {
  rows: ente.cnog[] = [] as ente.cnog[];
  loading: boolean = false;
  async created() {
    this.loading = true;
    this.rows = await cnogService.List();
    this.loading = false;
  }

  filter: string = null
  get list(): ente.cnog[] {
    return this.rows.filter(f => !this.filter || (this.filter && (f.nome.toLowerCase().includes(this.filter.toLowerCase()) || f.descrizione.toLowerCase().includes(this.filter.toLowerCase()))))
  }
}
