import { Vue, Component } from 'vue-property-decorator';
import { ctsService } from '../service/ctsService';

@Component({
  name: 'ListaCts',
})
export default class ListaCts extends Vue {
  rows: ente.cts[] = [] as ente.cts[];

  loading: boolean = false;
  async created() {
    this.loading = true;
    this.rows = await ctsService.List();
    this.loading = false;
  }

  filter: string = null
  get list(): ente.cts[] {
    return this.rows.filter(f => !this.filter || (this.filter && (f.nome.toLowerCase().includes(this.filter.toLowerCase()) || f.descrizione.toLowerCase().includes(this.filter.toLowerCase()))))
  }
}
