import { Vue, Component, Watch } from 'vue-property-decorator';
import { etfService } from '../service/etfService';
import ManageEtf from '../components/manageEtf.vue';
import { debounce } from '@/services/_base/debounce';
import { capitalizeFirstLetter } from '@/utils/utils';

@Component({
  name: 'ListaEtf',
  components: {
    ManageEtf
  }
})
export default class ListaEtf extends Vue {
  list: ente.etf[] = [] as ente.etf[];
  manage: ente.etf = null;
  manageVisible = false;

  pagination: { pageSizes: number[], total: number, } = {
    pageSizes: [20, 40, 60],
    total: 0
  }

  public query: ente.queryParams = {
    text: null,
    place: null,
    orderField: "Nome",
    orderDescending: false,
    page: 1,
    take: 20,
  };

  @Watch('query.text')
  queryTextChanged(n, o) {
    if (n !== o) {
      this.loading = true;
      this.fetchDataDebounced(this);
    }
  }

  @Watch('query.place')
  queryPlaceChanged(n, o) {
    if (n !== o) {
      this.loading = true;
      this.fetchDataDebounced(this);
    }
  }

  private fetchDataDebounced = debounce(async (self: this) => {
    await self.fetchData()
  }, 400);


  async created() {
    this.fetchData()
  }

  paginationSizeChange(size) {
    this.query.take = size;
    this.query.page = 1;
    this.fetchData();
  }

  paginationPageChange(page) {
    this.query.page = page;
    this.fetchData();
  }

  sortChange({ column, prop, order }) {
    this.query.orderField = capitalizeFirstLetter(prop);
    this.query.orderDescending = order === 'descending';
    this.fetchData();
  }

  loading: boolean = false;
  async fetchData() {
    this.loading = true;
    this.pagination.total = await etfService.Count(this.query);
    this.list = await etfService.List(this.query);
    this.loading = false;
  }

  handleEdit(row: ente.etf) {
    this.manage = row;
    this.manageVisible = true;
  }

  handleRemove(row: ente.etf) {
    this.$msgbox({
      title: `Attenzione`,
      message: `L'ente terzo formatore <strong>${row.nome}</strong> verrà cancellato definitivamente. Continuare?`,
      showCancelButton: true,
      confirmButtonText: `Si`,
      cancelButtonText: `Annulla`,
      dangerouslyUseHTMLString: true,
      type: 'warning'
    })
      .then(() => {
        etfService
          .Remove(row.id)
          .then(() => this.fetchData())
          .catch(error => {
            console.error(error);
            this.$message({
              message: `Si è verificato un errore nella cancellazione dell'ente terzo formatore <strong>${row.nome}</strong>`,
              dangerouslyUseHTMLString: true,
              type: 'error',
              duration: 7200
            });
          });
      })
      .catch((e) => {
        console.info(e);
      });
  }

  async onChange(elem: ente.etf) {
    this.fetchData();
    this.manageVisible = false;
  }

  onClose() {
    this.manageVisible = false;
  }
}
