import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";

class CTSService extends baseRestService {

    constructor() {
        super();
        this.baseUrl = () => settings.baseUrl;
    }

    async Count(params?: ente.queryParams): Promise<number> {
        return await this.Get<number>(`/api/anagrafica/comitato-tecnico-scientifico/count`, params);
    }

    async List(params?: ente.queryParams): Promise<ente.cts[]> {
        return await this.Get<ente.cts[]>(`/api/anagrafica/comitato-tecnico-scientifico`, params);
    }

    async Detail(id: string): Promise<ente.cts> {
        return await this.Get<ente.cts>(`/api/anagrafica/comitato-tecnico-scientifico/${id}`);
    }
}

export const ctsService = new CTSService();