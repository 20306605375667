import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";

class AziendaService extends baseRestService {

    constructor() {
        super();
        this.baseUrl = () => settings.baseUrl;
    }

    async Count(params?: ente.queryParams): Promise<number> {
        return await this.Get<number>(`/api/anagrafica/aziende/count`, params);
    }

    async List(params?: ente.queryParams): Promise<ente.azienda[]> {
        return await this.Get<ente.azienda[]>(`/api/anagrafica/aziende`, params);
    }

    async Detail(id: string): Promise<ente.azienda> {
        return await this.Get<ente.azienda>(`/api/anagrafica/aziende/${id}`);
    }

    async Set(obj: ente.azienda): Promise<ente.azienda> {
        return await this.Post<ente.azienda>(`/api/anagrafica/aziende`, obj);
    }

    async Remove(id: string): Promise<void> {
        return await this.Delete(`/api/anagrafica/aziende/${id}`);
    }
}

export const aziendaService = new AziendaService();