import { Vue, Component, Prop } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import { aziendaService } from '../service/aziendaService';


@Component({
    name: "ManageAzienda"
})
export default class ManageAzienda extends Vue {
    @Prop()
    value?: ente.azienda;

    get validationRules() {
        return {
            nome: [{ required: true, trigger: 'blur' }],
            ragioneSociale: [{ required: true, trigger: 'blur' }],
            sedeLegale: [{ required: true, trigger: 'blur' }],
        }
    }

    manage: ente.azienda = {
        id: null,
        nome: null,
        sedeLegale: null,
        telefono: null,
        email: null
    } as ente.azienda;
    created() {
        if (this.value) {
            this.manage = Object.assign({}, this.value);
        }
    }

    get title() {
        if (!this.manage?.id)
            return `Aggiungi una nuova azienda`
        return `Modifica l'azienda <strong>${this.value.nome}</strong>`
    }

    saving: boolean = false;
    async save() {
        (this.$refs.form as ElForm)?.validate(async valid => {
            if (valid) {
                try {
                    this.saving = true;
                    const result = await aziendaService.Set(this.manage);
                    this.saving = false;
                    this.$emit('change', result);
                } catch (e) {
                    console.log(e);
                    this.saving = false;
                }
            }
        })
    }
}