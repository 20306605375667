import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";

class CNOGService extends baseRestService {

    constructor() {
        super();
        this.baseUrl = () => settings.baseUrl;
    }

    async Count(params?: ente.queryParams): Promise<number> {
        return await this.Get<number>(`/api/anagrafica/ordini-nazionali/count`, params);
    }

    async List(params?: ente.queryParams): Promise<ente.cnog[]> {
        return await this.Get<ente.cnog[]>(`/api/anagrafica/ordini-nazionali`, params);
    }

    async Detail(id: string): Promise<ente.cnog> {
        return await this.Get<ente.cnog>(`/api/anagrafica/ordini-nazionali/${id}`);
    }
}

export const cnogService = new CNOGService();