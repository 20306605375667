import { authService } from '@/modules/account/services/authService';
import { corsiService } from '@/modules/corsi/services/corsiService';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { iscrittoService } from '../services/iscrittoService';
import { formatTime } from '@/filters';
import { store } from '@/store';

@Component({})
export default class ConfermaIscrizione extends Vue {
  @Prop({ required: true })
  corsoId: string;

  @Prop({ required: true })
  anagraficaGiornalistaId: string;

  user: user.summary = null;
  corso: corso.item = null;
  iscritto: iscritto.item = null;

  loading: boolean = false;
  card: string = null;;
  async created() {
    this.loading = true;

    this.user = store.getters.login.userInfo() || await authService.WhoAmI()
    if (this.anagraficaGiornalistaId !== this.user.anagraficaGiornalistaId) {
      await authService.Logout();
      window.location.reload();
    }


    this.corso = await corsiService.Detail(this.corsoId);
    this.iscritto = await iscrittoService.Detail(this.corsoId, this.user.anagraficaGiornalistaId);

    if (!this.iscritto) {
      this.card = "non-iscritto";
    } else {
      if (!this.iscritto.ammesso && !this.iscritto.cancellato) {
        this.card = "conferma";
      } else {
        if (this.iscritto.cancellato) {
          this.card = "iscrizione-cancellata";
        } else {
          this.card = "ammesso";
        }
      }
    }
    this.loading = false;
  }

  async annulla() {
    iscrittoService.Remove(this.corsoId, this.user.anagraficaGiornalistaId, "Invito inviato tramite mail per conferma iscrizione rifiutato").then(() => {
      this.$message({
        message: "Iscrizione al corso annullata con successo",
        type: 'success',
        showClose: true
      });
      this.goToDetail()
    }).catch((err) => {
      this.$message({
        message: "Si è verificato un problema nella cancellazione dell'iscrizione al corso",
        showClose: true,
        type: "error"
      });
    });
  }

  async accetta() {
    // controlla se posti disponibili
    const check = await this.controllaSePostiDisponibili()
    if (!check) {
      this.$message({
        message: "<strong>Siamo spiacenti, i posti disponibili sono stati occupati, verrai ricontattato se si libera un posto.</strong",
        center: true,
        duration: 5 * 1000,
        showClose: true,
        type: 'error',
        dangerouslyUseHTMLString: true
      });
      return;
    }

    await iscrittoService.Ammetti(this.corsoId, this.iscritto.anagraficaGiornalistaId).then(() => {
      this.$message({
        message: "Partecipazione al corso confermata con successo",
        showClose: true,
        duration: 5 * 1000,
        type: 'success'
      });
      this.goToDetail()
    }).catch((err) => {
      this.$message({
        message: "Si è verificato un problema nella conferma di partecipazione al corso",
        showClose: true,
        type: "error"
      });
    });
  }

  async controllaSePostiDisponibili() {
    const ammessiAlCorso = await iscrittoService.Count(this.corsoId, { ammesso: true, cancellato: false } as iscritto.queryParams);
    return this.corso.numeroMassimoPartecipanti - ammessiAlCorso > 0;
  }

  goToDetail() {
    this.$router.push({ name: 'giornalistiCorsoDetail', params: { corsoId: this.corsoId } });
  }
}
