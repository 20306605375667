import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";

class ETFService extends baseRestService {

    constructor() {
        super();
        this.baseUrl = () => settings.baseUrl;
    }

    async Count(params?: ente.queryParams): Promise<number> {
        return await this.Get<number>(`/api/anagrafica/enti-terzi-formatori/count`, params);
    }

    async List(params?: ente.queryParams): Promise<ente.etf[]> {
        return await this.Get<ente.etf[]>(`/api/anagrafica/enti-terzi-formatori`, params);
    }

    async Detail(id: string): Promise<ente.etf> {
        return await this.Get<ente.etf>(`/api/anagrafica/enti-terzi-formatori/${id}`);
    }

    async Set(obj: ente.etf): Promise<ente.etf> {
        return await this.Post<ente.etf>(`/api/anagrafica/enti-terzi-formatori`, obj);
    }

    async Remove(id: string): Promise<void> {
        return await this.Delete(`/api/anagrafica/enti-terzi-formatori/${id}`);
    }
}

export const etfService = new ETFService();